<template>
    <el-row style="width: 98%">
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" class="row">
            <el-col class="order-title">
                <span>软件商城订单</span>
            </el-col>
        </el-col>
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" class="row">
            <el-col id="order-detail">
                <el-tabs v-model="orderStatus" type="card" @tab-click="handleClick" class="order-tabs">
                    <el-tab-pane label="全部订单" name="0" class="tab-text"></el-tab-pane>
                    <el-tab-pane label="待付款" name="1" class="tab-text"></el-tab-pane>
                    <el-tab-pane label="待发货" name="2" class="tab-text"></el-tab-pane>
                    <el-tab-pane label="已发货" name="3" class="tab-text"></el-tab-pane>
                    <el-tab-pane label="已完成" name="4" class="tab-text"></el-tab-pane>
                    <el-tab-pane style="margin-left:50%"></el-tab-pane>
                </el-tabs>
<!--              <el-col style="width: 100%">-->
              <div class="order-tables" v-show="tableData.length>0">
                    <div v-for="(item, index) in tableData" class="order-table">
                        <el-table
                            v-loading="loading"
                             :data="item.childList"
                             :row-style="tableRowStyle"
                             :cell-style="tableCellStyle"
                             :header-cell-style="tableHeaderCellStyle">
                            <!--  近三个月订单  -->
                          <el-table-column label="近三个月订单" v-if="index == 0" >
                            <el-table-column :label="'订单号：'+item.orderNo" width="300" >
                                <template slot-scope="scope">
<!--                                    <img :src="scope.row.goodsImageUrl"-->
<!--                                         style="position: absolute;">-->
                                    <img src="../../img/softwaremall/swmallOrder/product_icon/illustrator.svg"
                                         style="position: absolute;">
                                    <span
                                         style="line-height: 53px;margin-left: 67px;">{{ scope.row.softwareGoodsName }}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column :label="'订单号：'+item.orderNo"  v-if="index !== 0" width="300">
                            <template slot-scope="scope">
                              <!--                                    <img :src="scope.row.goodsImageUrl"-->
                              <!--                                         style="position: absolute;">-->
                              <img src="../../img/softwaremall/swmallOrder/product_icon/illustrator.svg"
                                   style="position: absolute;">
                              <span
                                  style="line-height: 53px;margin-left: 67px;">{{ scope.row.softwareGoodsName }}</span>
                            </template>
                          </el-table-column>
                            <!-- 规格 -->
                          <el-table-column label="规格" align="center" v-if="index == 0">
                            <el-table-column prop="specifications"  >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.specsName }}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="specifications"  v-if="index !== 0" >
                            <template slot-scope="scope">
                              <span>{{ scope.row.specsName }}</span>
                            </template>
                          </el-table-column>
                            <!-- 数量  -->
                          <el-table-column label="数量" align="center" v-if="index == 0">
                            <el-table-column prop="number"  :label="item.orderGoodsNum + ''"  >
                                <template slot-scope="scope">
                                    <span>*{{scope.row.softwareNum}}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="number"  :label="item.orderGoodsNum + ''"   v-if="index !== 0">
                            <template slot-scope="scope">
                              <span>*{{scope.row.softwareNum}}</span>
                            </template>
                          </el-table-column>
                            <!-- 产品类型  -->
                          <el-table-column label="产品类型" align="center" v-if="index == 0">
                            <el-table-column prop="productType"  >
                                <template slot-scope="scope">
                                    <span>{{scope.row.softwareGoodsType == 1 ? '软件' : '硬件'}}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="productType"  v-if="index !== 0" >
                            <template slot-scope="scope">
                              <span>{{scope.row.softwareGoodsType == 1 ? '软件' : '硬件'}}</span>
                            </template>
                          </el-table-column>
                            <!-- 订单状态      -->
                          <el-table-column label="订单状态" align="center" v-if="index == 0">
                            <el-table-column prop="status"  :label="checkOrderStatus(item.orderStatus)" >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.status == 1">
                                        <p class="status-circle" :style="{'background-color': getColor('notPay')}">&nbsp;</p>
                                        <p class="status-text">未发货</p>
                                    </span>
                                    <span v-if="scope.row.status == 2">
                                        <p class="status-circle" :style="{'background-color': getColor('isFaHuo')}">&nbsp;</p>
                                        <p class="status-text">已发货</p>
                                    </span>
                                    <span v-if="scope.row.status == 3">
                                        <p class="status-circle" :style="{'background-color': getColor('isReturn')}">&nbsp;</p>
                                        <p class="status-text">已完成</p>
                                    </span>
                                    <span v-if="scope.row.status == 4">
                                        <p class="status-circle" :style="{'background-color': getColor('cancelOrder')}">&nbsp;</p>
                                        <p class="status-text">已取消</p>
                                    </span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="status"  :label="checkOrderStatus(item.orderStatus)"  v-if="index !== 0">
                            <template slot-scope="scope">
                                    <span v-if="scope.row.status == 1">
                                        <p class="status-circle" :style="{'background-color': getColor('notPay')}">&nbsp;</p>
                                        <p class="status-text">未发货</p>
                                    </span>
                              <span v-if="scope.row.status == 2">
                                        <p class="status-circle" :style="{'background-color': getColor('isFaHuo')}">&nbsp;</p>
                                        <p class="status-text">已发货</p>
                                    </span>
                              <span v-if="scope.row.status == 3">
                                        <p class="status-circle" :style="{'background-color': getColor('isReturn')}">&nbsp;</p>
                                        <p class="status-text">已完成</p>
                                    </span>
                              <span v-if="scope.row.status == 4">
                                        <p class="status-circle" :style="{'background-color': getColor('cancelOrder')}">&nbsp;</p>
                                        <p class="status-text">已取消</p>
                                    </span>
                            </template>
                          </el-table-column>
                            <!-- 收货人 -->
                          <el-table-column label="收货人" align="center" v-if="index == 0">
                            <el-table-column prop="consignee" :label="item.userName"  >
                                <template slot-scope="scope">
                                    <span>{{ item.userName }}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="consignee" :label="item.userName"  v-if="index !== 0">
                            <template slot-scope="scope">
                              <span>{{ item.userName }}</span>
                            </template>
                          </el-table-column>
                            <!-- 价格  -->
                          <el-table-column label="价格" align="center" v-if="index == 0">
                            <el-table-column prop="price" :label="item.orderPrice + ''"  >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.softwareAllPrice }}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="price" :label="item.orderPrice + ''"   v-if="index !== 0">
                            <template slot-scope="scope">
                              <span>{{ scope.row.softwareAllPrice }}</span>
                            </template>
                          </el-table-column>
                            <!-- 交易时间  -->
                          <el-table-column label="交易时间" align="center" v-if="index == 0">
                            <el-table-column prop="date" :label="item.orderPayTime"  >
                                <template slot-scope="scope">
                                    <span>{{ item.orderPayTime }}</span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column prop="date" :label="item.orderPayTime"   v-if="index !== 0">
                            <template slot-scope="scope">
                              <span>{{ item.orderPayTime }}</span>
                            </template>
                          </el-table-column>
                            <!-- 操作  -->
                          <el-table-column label="操作" align="center" v-if="index == 0">
                            <el-table-column  >
                                <template slot-scope="scope">
                                    <span v-if="item.orderStatus == 1" style="color: #A3F338;font-size: 14px">
                                        <span class="handle"
                                              :style="{'color': getColor('cancelOrder')}"
                                              @click="cancelOrder(item, scope.row)">取消订单</span>
                                    </span>
                                    <span
                                         v-if="item.orderStatus != 1 && (scope.row.softwareGoodsType == 2 || (scope.row.softwareGoodsType == 1 && scope.row.status == 1))"
                                         :style="{'color': getColor('returnApply')}">
                                        <span class="handle cancel-order" @click="afterSale(item, scope.row)">售后</span>
                                    </span>
                                    <span v-if="scope.row.status == 2" :style="{'color': getColor('returnAudit')}">
                                        <span class="handle cancel-order" @click="deliverSubmit(item, scope.row)">确认收货</span>
                                    </span>
                                    <span
                                         v-if="scope.row.softwareGoodsType == 2 && (scope.row.status == 2 || scope.row.status == 3)"
                                         :style="{'color': getColor('returning')}">
                                        <span class="handle cancel-order">查看物流</span>
                                    </span>
                                    <span v-if="scope.row.status == 3 && scope.row.softwareGoodsType == 1"
                                          :style="{'color': getColor('isQianShou')}">
                                        <span class="handle cancel-order">已完成</span>
                                    </span>
                                </template>
                            </el-table-column>
                          </el-table-column>
                          <el-table-column   v-if="index !== 0">
                            <template slot-scope="scope">
                                    <span v-if="item.orderStatus == 1" style="color: #A3F338;font-size: 14px">
                                        <span class="handle"
                                              :style="{'color': getColor('cancelOrder')}"
                                              @click="cancelOrder(item, scope.row)">取消订单</span>
                                    </span>
                              <span
                                  v-if="item.orderStatus != 1 && (scope.row.softwareGoodsType == 2 || (scope.row.softwareGoodsType == 1 && scope.row.status == 1))"
                                  :style="{'color': getColor('returnApply')}">
                                        <span class="handle cancel-order" @click="afterSale(item, scope.row)">售后</span>
                                    </span>
                              <span v-if="scope.row.status == 2" :style="{'color': getColor('returnAudit')}">
                                        <span class="handle cancel-order" @click="deliverSubmit(item, scope.row)">确认收货</span>
                                    </span>
                              <span
                                  v-if="scope.row.softwareGoodsType == 2 && (scope.row.status == 2 || scope.row.status == 3)"
                                  :style="{'color': getColor('returning')}">
                                        <span class="handle cancel-order">查看物流</span>
                                    </span>
                              <span v-if="scope.row.status == 3 && scope.row.softwareGoodsType == 1"
                                    :style="{'color': getColor('isQianShou')}">
                                        <span class="handle cancel-order">已完成</span>
                                    </span>
                            </template>
                          </el-table-column>
                        </el-table>
                        <div class="load-data" v-if="item.orderGoods && item.orderGoods.length > 2"
                             @click="loadMoreData(item)">
                            {{ loadText }}
                        </div>
                    </div>
                </div>
              <div class="order-tables" v-show="tableData.length==0">
                <div  class="order-table">
                  <el-table
                      v-loading="loading"
                      :data="tableData"
                      :row-style="tableRowStyle"
                      :cell-style="tableCellStyle"
                      :header-cell-style="tableHeaderCellStyle">
                    <!--  近三个月订单  -->
                    <el-table-column label="近三个月订单" >
                    </el-table-column>
                    <!-- 规格 -->
                    <el-table-column label="规格" align="center">
                    </el-table-column>
                    <!-- 数量  -->
                    <el-table-column label="数量" align="center" >
                    </el-table-column>
                    <!-- 产品类型  -->
                    <el-table-column label="产品类型" align="center">
                    </el-table-column>
                    <!-- 订单状态      -->
                    <el-table-column label="订单状态" align="center">
                    </el-table-column>
                    <!-- 收货人 -->
                    <el-table-column label="收货人" align="center">
                    </el-table-column>
                    <!-- 价格  -->
                    <el-table-column label="价格" align="center">
                    </el-table-column>
                    <!-- 交易时间  -->
                    <el-table-column label="交易时间" align="center">
                    </el-table-column>
                    <!-- 操作  -->
                    <el-table-column label="操作" align="center" >
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              </el-col>
            </el-col>

            <el-col clsaa="paging">
                <Page
                     :total="page.total"
                     :current="page.pageNo"
                     :page-size="page.pageSize"
                     @on-page-size-change="onPageSizeChange"
                     @on-change="changePage"
                     show-total show-elevator style="font-size: 14px"></Page>
            </el-col>

        </el-col>

        <!-- 售后-->
        <div>
            <Drawer v-model="saleDrawer" :closable="true" width="550" :mask-closable="false" title="申请售后">
                <el-form ref="saleRef" :model="saleForm" :rules="saleRules" label-width="80">
                    <el-form-item label="售后类型" prop="type">
                        <el-radio-group v-model="saleForm.type">
                            <el-radio label="1" :disabled="goodsInfo.status != 1">退款</el-radio>
                            <el-radio label="2" :disabled="goodsInfo.status == 1">退货</el-radio>
                            <el-radio label="3" :disabled="goodsInfo.status == 1">换货</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="申请原因" prop="reason">
                        <el-input maxlength="255" show-word-limit type="textarea" rows="3" v-model="saleForm.reason"/>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <i-button class="footer-button1" type="primary" @click="submitSale('saleRef')">提交</i-button>
                    <i-button class="footer-button2"  @click="resetForm('saleRef'), saleDrawer = false">取消</i-button>
                </div>
            </Drawer>

        </div>
    </el-row>

</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import api from '@/api/buyer/softwareMallOrder.js'

Vue.use(iView)
export default {
    name: "SoftwareMallOrder",
    data() {
        return {
          header: false,
          loading: true,
            activeName: 'first',
            tableData: [],
            page: {
                pageNo: 1,
                pageSize: 5,
                total: 0
            },
            orderStatus: 0,
            statusList: [
                {status: 1, text: '待付款'},
                {status: 2, text: '待发货'},
                {status: 3, text: '发货中'},
                {status: 4, text: '已发货'},
                {status: 5, text: '已完成'},
                {status: 6, text: '已取消'},
                {status: 7, text: '已下单'},
            ],
            loadText: '加载更多数据',
            colorList: [
                {key: 'isPay', color: '#e36ad7'},   //已支付
                {key: 'notPay', color: '#cd7169'},  //未支付
                {key: 'isFaHuo', color: '#8463f3'}, //已发货
                {key: 'isQianShou', color: '#8cf2be'},  //已签收
                {key: 'cancelOrder', color: '#969696'}, //取消订单
                {key: 'returnApply', color: '#e9be73'}, //退货申请
                {key: 'returnAudit', color: '#db8c4e'}, //退货审核
                {key: 'returning', color: '#e88368'},   //退货中
                {key: 'auditOut', color: '#ed6560'},    //审核不通过
                {key: 'isReturn', color: '#8ef4b4'},    //已退货
            ],
            orderInfo: {},  //订单详情
            goodsInfo: {},  //订单商品详情
            saleDrawer: false,  //售后
            saleForm: {
                type: '',   //售后类型
                reason: '', //申请原因
            },
            saleRules: {
                type: [{required: true, message: '请选择售后类型', trigger: 'change'}],
                reason: [{required: true, message: '请输入申请原因', trigger: 'change'}],
            }
        };
    },
    mounted() {
      document.body.style.overflow = 'hidden';
        this.listOrder();
    },
    methods: {
        deliverSubmit(order, goods) {
            this.$confirm('温馨提示：请确认是否收到货物', '确认收货').then(() => {
                api.deliverGoods({
                    softwareOrderId: order.softwareOrderId,
                    orderGoodsId: goods.orderGoodsId
                }).then(res => {
                    if(res.code != 200) {
                        return this.buyerMsg(res.message, 'error');
                    }
                    this.buyerMsg('确认收货成功');
                    this.page.pageNo = 1;
                    this.listOrder();
                })
            })
        },
        handleClose(drawer, ref) {
            this.resetForm(ref);
            this[drawer] = false;
        },
        submitSale(ref) {
            if(this.validateForm(ref)) {
                let type = this.saleForm.type;
                if(type == 1) {
                    this.saleRefund(ref);
                }else if(type == 2) {
                    this.saleRefundGoods(ref);
                }else {
                    this.saleRefundChange(ref);
                }
            }
        },
        //退款
        saleRefund(ref) {
            let goods = this.goodsInfo;
            let order = this.orderInfo;
            let form = this.saleForm;
            api.refund({
                orderGoodsId: goods.orderGoodsId,
                softwareOrderId: order.softwareOrderId,
                afterSaleReasons: form.reason,
                softwareGoodsName: order.softwareGoodsName
            }).then(res => {
                if(res.code != 200) {
                    return this.buyerMsg(res.message, 'error');
                }
                this.buyerMsg('申请退款成功，等待审核');
                this.resetForm(ref);
                this.saleDrawer = false;
                this.page.pageNo = 1;
                this.listOrder();
            })
        },
        //退货
        saleRefundGoods(ref) {
            let goods = this.goodsInfo;
            let order = this.orderInfo;
            let form = this.saleForm;
            api.refundGoods({
                orderGoodsId: goods.orderGoodsId,
                softwareOrderId: order.softwareOrderId,
                afterSaleReasons: form.reason,
                softwareGoodsName: order.softwareGoodsName
            }).then(res => {
                if(res.code != 200) {
                    return this.buyerMsg(res.message, 'error');
                }
                this.buyerMsg('申请换货成功，等待审核');
                this.resetForm(ref);
                this.saleDrawer = false;
                this.page.pageNo = 1;
                this.listOrder();
            })
        },
        //换货
        saleRefundChange(ref) {
            let goods = this.goodsInfo;
            let order = this.orderInfo;
            let form = this.saleForm;
            api.changeGoods({
                orderGoodsId: goods.orderGoodsId,
                softwareOrderId: order.softwareOrderId,
                afterSaleReasons: form.reason,
                softwareGoodsName: order.softwareGoodsName
            }).then(res => {
                if(res.code != 200) {
                    return this.buyerMsg(res.message, 'error');
                }
                this.buyerMsg('申请换货成功，等待审核');
                this.resetForm(ref);
                this.saleDrawer = false;
                this.page.pageNo = 1;
                this.listOrder();
            })
        },
        afterSale(order, goods) {
            this.orderInfo = order;
            this.goodsInfo = goods;
            this.saleDrawer = true;
        },
        getColor(key) {
            let color = 'black';
            this.colorList.forEach(item => {
                if (item.key === key) {
                    color = item.color;
                }
            })
            return color;
        },
        cancelOrder(val, childVal) {
            this.$confirm('温馨提示：取消当前订单将影响剩余订单，请确认是否执行当前操作', '取消订单确认').then(() => {
                api.orderCancel({
                    softwareOrderId: val.softwareOrderId
                }).then(res => {
                    if (res.code != 200) {
                        return this.buyerMsg(res.message, 'error');
                    }
                    this.buyerMsg('取消成功');
                    this.listOrder();
                })
            })
        },
        listOrder() {
            api.listOrders({
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                orderStatus: this.orderStatus
            }).then(res => {
              this.timer = setTimeout(()=>{   //设置延迟执行
                this.loading = false
              },300)
                if (res.data) {
                    this.tableData = res.data.records;
                    this.tableData.forEach(item => {
                        this.$set(item, 'maxNum', item.orderGoods.length);
                        this.$set(item, 'ifMore', false);
                        this.$set(item, 'childList', item.orderGoods.slice(0, 2));
                    })
                    this.page.total = res.data.total;
                }
            })
        },
        //表单验证
        validateForm(ref) {
            let ifPass = false;
            this.$refs[ref].validate(valid => {
                ifPass = valid;
            });
            return ifPass;
        },
        resetForm(ref) {
           let formRef = this.$refs[ref];
           if(formRef) {
               formRef.resetFields();
           }
        },
        checkOrderStatus(status) {
            if (!status) {
                return '';
            }
            let val = '';
            this.statusList.forEach(item => {
                if (item.status == status) {
                    val = item.text;
                }
            })
            return val;
        },
        onPageSizeChange(val) {
            this.page.pageSize = val;
            this.listOrder();
        },
        changePage(val) {
            this.page.pageNo = val;
            this.listOrder();
        },
        handleClick(tab, event) {
            this.page.pageNo = 1;
            this.listOrder();
        },
        tableRowStyle({row, rowIndex}) {
            // 修改产品行样式
            let productRowSty = {
                "background-color": "#FAFAFA !important",
                "border-top": "2px solid #FFF",
                "border-bottom": "none"
            }

            if (row.hasOwnProperty('productName')) {
                return productRowSty;
            }
            return null;
        },
        tableCellStyle({row, column, rowIndex, columnIndex}) {
            // 修改第一列的单元格
            let productCellSty = {
                "padding-left": "32px",
            }
            if (columnIndex == 0 && row.hasOwnProperty('productName')) {
                return productCellSty;
            }
            return null;

        },
        tableHeaderCellStyle({row, column, rowIndex, columnIndex}) {
            // 修改表头第一列
            let headerCell = {
                "padding-left": "32px"
            }
            if (columnIndex == 0) {
                return headerCell;
            }
            return null;
        },
        loadMoreData(val) {
            val.ifMore = !val.ifMore;
            if (val.ifMore) {
                val.childList = val.orderGoods;
                this.loadText = '收起更多数据';
            } else {
                val.childList = val.orderGoods.slice(0, 2);
                this.loadText = '加载更多数据';
            }
        },
    },
    created() {
        window.document.body.style.backgroundColor = "#FFFFFF";
    }
}
</script>

<style scoped>
/deep/ .el-table tr {
  color: black;
}
    .order-tables {
        background: #FDF0EF;
    }
    .order-table {
        margin: 1.5% 0%;
    }
    .row {
        float: unset;
        margin: 0px 3%;
        /*width: 1626px;*/
        min-width: 1200px;
    }

.order-title {
    font-size: 25px;
    color: #F76F66;
    margin-top: 20px;
    margin-bottom: 25px;
}

/deep/ #order-detail .el-tabs--card > .el-tabs__header {
    border: none;
    margin: 0 0 7px;
}

/deep/ #order-detail .el-tabs--card > .el-tabs__header .el-tabs__item,
/deep/ #order-detail .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
}

/deep/ #order-detail .el-tabs__nav {
    height: 63px;
}

/deep/ #order-detail .el-tabs__item:hover,
/deep/ #order-detail .el-tabs__item.is-active,
/deep/ #order-detail .el-icon-search:before {
    color: #F76F66;
}

/deep/ #order-detail .el-tabs__item {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 48px;
}


.tab-text {
    font-size: 14px;
}

.order-search {
    width: 332px;
    margin-left: 250%;
}

/deep/ #order-detail .el-input__inner {
    height: 40px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid rgba(65, 65, 65, 0.2000);
}

/deep/ #order-detail .el-input-group__append {
    border: 0;
    background-color: #FFFFFF;
    border: 1px solid rgba(65, 65, 65, 0.2000);
    border-left: none;
    border-radius: 0px 8px 8px 0px;
}

    .fixed-table {
        /*width: 1626px;*/
        border-collapse: collapse;

}

    .fixed-header {
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        background-color: #F7F7F7;
        height: 50px;
        border: none;
    }

::v-deep #order-detail .el-table__body {
    border-collapse: collapse;
}

/deep/ #order-detail .el-table th.el-table__cell {
    background-color: #F7F7F7;
}

/deep/ #order-detail .el-table td:nth-child(n+2).el-table__cell,
::v-deep #order-detail .el-table th:nth-child(n+2).el-table__cell.is-leaf {
    text-align: center;
}

/deep/ #order-detail .el-table td.el-table__cell,
::v-deep #order-detail .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}

::v-deep #order-detail .el-table th.el-table__cell.is-leaf {
    font-size: 14px;
    color: #414141;
    line-height: 19px;
    font-weight: normal;
}

::v-deep #order-detail .el-table [class*=el-table__row--level] .el-table__expand-icon {
    display: none;
}

.status-circle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    display: inline-block;
    margin: 1px;
    background-color: rgb(247, 111, 102);
    vertical-align: middle;
}

.status-text {
    margin: 1px 1px 1px 10px;
    display: inline-block;
    line-height: 19px;
    vertical-align: middle;
    font-size: 14px;
    color: #414141;
}

/deep/ .status-1 {
    color: #F86060;
    font-size: 14px;
}

/deep/ .status-2 {
    color: #A3F338;
    font-size: 14px;
}

/deep/ .status-3 {
    color: #FFC800;
    font-size: 14px;
}

.handle {
    width: 28px;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    margin-right: 22px;
    cursor: pointer;
}

.cancel-order {
    color: #F46E64;
}

/*隐藏table 右侧滚动条 */
/deep/ #order-detail .el-table--scrollable-y .el-table__body-wrapper {
    overflow-y: hidden;
}

/deep/ #order-detail .el-table::before {
    display: none;
}

.load-data {
    /*background-color: #FDF0EF;*/
    /*line-height: 38px;*/
    font-size: 14px;
    text-align: center;
    /*width: 1626px;*/
    margin-top: 25px;
    /*border-radius: 8px;*/
    cursor: pointer;
}

/deep/ .ivu-page {
    float: right;
    margin-top: 8px;
    margin-right: 143px;
}

/*已付款*/
.already-pay {
    color: #f85fd9;
}

/*未付款*/
.not-pay {
    color: #fb5c62;
}

/*已发货*/
.already-ship {
    color: #8861f8;
}

/*已签收*/
.already-sign {
    color: #5ff7ba;
}

/*取消交易*/
.cancel-trade {
    color: #969696;
}

/*退货申请*/
.return-goods {
    color: #f8bd61;
}

/*退货审核*/
.return-audit {
    color: #ff8123;
}

/*退货中*/
.returning {
    color: #f77e61;
}

/*审核未通过*/
.audit-out {
    color: #f8605f;
}

/*已退货*/
.is-return {
    color: #5ff7ad;
}
.drawer-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 10%;
    text-align: right;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

.footer-button1 {
    margin-right: 6%;
    margin-top: 5%;
}

.footer-button2 {
    margin: 5% 10% 0% 0%;
}

    /deep/ .ivu-page {
        float: right;
        margin-top: 8px;
        margin-right: 143px;
    }
    /deep/.el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden !important;
    }
</style>
