import {get,post} from "@/utils/axios";

const listOrders = p => get("/api-seller/software/getSoftwareOrderListByConsole", p);

//取消订单
const orderCancel = p => post("/api-seller/software/cancelOrder", p);

//退款
const refund = p => post("/api-seller/software/applyRefund", p);

//退货
const refundGoods = p => post("/api-seller/software/returnGoods", p);

//换货
const changeGoods = p => post("/api-seller/software/exchangeGoods", p);

//确认收获
const deliverGoods = p => post("/api-seller/software/deliverGoods", p);

export default {
    listOrders,
    orderCancel,
    refund,
    refundGoods,
    changeGoods,
    deliverGoods,
}